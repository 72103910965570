import { Component } from "react";
import Effects from "../Effects";

const desc = "Гордеем се с това, което имаме. Ето и нещата, с които се отличаваме:";

let roadmapList = [
    {
        imgUrl: 'assets/images/roadmap/01.png',
        imgAlt: 'Roadmap Icon',
        title: 'Играчът решава',
        desc: 'Твоята визия за FiveM RP е наш приоритет - ти си този, който решава следващите актуализации.',
    },
    {
        imgUrl: 'assets/images/roadmap/02.png',
        imgAlt: 'Roadmap Icon',
        title: 'Качествен Roleplay',
        desc: 'Потопи се в автентичен и качествен Roleplay, изпълнен с уникални моменти в нашия FiveM сървър.',
    },
    {
        imgUrl: 'assets/images/roadmap/03.png',
        imgAlt: 'Roadmap Icon',
        title: 'Балансирана Икономика',
        desc: 'С нашата стабилна и балансирана икономика, FiveM става още по-завладяващо място за игра.',
    },
    {
        imgUrl: 'assets/images/roadmap/04.png',
        imgAlt: 'Roadmap Icon',
        title: 'Активна Разработка',
        desc: 'Изживей последните нововъведения във FiveM благодарение на активната ни разработка.',
    },
    {
        imgUrl: 'assets/images/roadmap/05.png',
        imgAlt: 'Roadmap Icon',
        title: 'Надежден Екип',
        desc: 'Нашият сървър се гордее с опитен, ангажиран и надежден екип, който слага твоите нужди на първо място.',
    },
    {
        imgUrl: 'assets/images/roadmap/06.png',
        imgAlt: 'Roadmap Icon',
        title: 'Не е Pay to Win',
        desc: 'MazenRP предоставя равен шанс на всеки играч, като успехът не зависи от размера на портфейла ти.',
    },
]



class RoadMap extends Component {
    render() {
        return (
            <section className="roadmap padding-top padding-bottom" id="roadmap">
                <div className="container">
                    <div className="section-header">
                        <div className="section-header__content">
                            <span>
                                <h3 >
                                    Какво ни прави&nbsp;
                                    <span style={{position: "relative"}} className="color--gradient-y">
                                        <Effects fill="#029561" />
                                        различни
                                    </span>
                                </h3>
                            </span>
                            <p>{desc}</p>
                        </div>
                    </div>
                    <div className="roadmap__wrapper">
                        <div className="row g-4">
                            {roadmapList.map((val, i) => (
                                <div className="featured-block col-lg-4 col-md-6" key={i}>
                                    <div className="roadmap__item wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                        <div className="roadmap__inner">
                                            <div className="roadmap__thumb">
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                            </div>
                                            <span className="border-one"></span>
                                            <span className="border-two"></span>
                                            <div className="roadmap__content">
                                                <h4>{val.title}</h4>
                                                <p>{val.desc}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            // <div className="featured-block col-lg-3 col-md-4 col-sm-12" key={i}>
                            //     <div className="inner-box wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">

                            //         <div className="icon-box">
                            //             <span className="icon"><img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></span>
                            //         </div>
                            //         <h5>{val.title}</h5>
                            //     </div>
                            // </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default RoadMap;