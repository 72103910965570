import { Component } from "react";


const title = <h3>Присъедини се в<span className="color--gradient-y d-block">MazenRP</span></h3>;
const desc = "Едно ново, по-мазно начало";
const btnText = "Discord";


class Community extends Component {
    render() { 
        return (
            <section className="community padding-top padding-bottom bg--fixed" style={{backgroundImage: "linear-gradient(rgba(0, 149, 154, 0.7), rgba(7, 59, 76, 0.7)), url(/assets/images/community/mazenmap3.jpg)"}}>
                <div className="container">
                    <div className="comminity__wrapper">
                        <div className="community__content text-center" data-aos="zoom-in" data-aos-duration="1000">
                            {title}
                            <p>{desc}</p>
                            <a href="https://discord.gg/mazenrp" target="_blank" className="default-btn"><span>{btnText}</span></a>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Community;