import { Component } from "react";

import { Link } from "react-router-dom";

class Header extends Component {
    render() { 
        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 200) {
                document.querySelector('.header').classList.add(['header--fixed'])
                document.querySelector('.container').classList.add(['header-content--fixed'])
            }else{
                document.querySelector('.header').classList.remove(['header--fixed'])
                document.querySelector('.container').classList.remove(['header-content--fixed'])
            }   
        });
        return (
            <header className="header">
                <div className="container">
                    <nav className="navbar navbar-expand-xl">
                        <Link className="navbar-brand" to="/"><img src="assets/images/logo/logo-mazen.png" alt="Mazen" /></Link>
                        <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler--icon"></span></button>
                        <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
                            <div className="navbar-nav">
                                <Link className="nav-link" to="/contact">Правила</Link>
                                <Link className="nav-link" to="/blog">Блог</Link>
                                <Link className="nav-link" to="/contact">Контакт</Link>
                            </div>
                            <div className="social-btns">
                                <a href="https://discord.gg/mazenrp" target="_blank" className="default-btn">
                                    <span>Discord</span>
                                </a>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
        );
    }
}
 
export default Header;