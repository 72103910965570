import classNames from "classnames";
import { motion } from "framer-motion";

const paths = [
  {
    d: "M25.7992 19.5432L25.7593 20.7886L30.3191 20.9345L30.359 19.6891L25.7992 19.5432ZM27.3499 22.6162L28.6247 22.657L28.7775 17.8814L27.5027 17.8407L27.3499 22.6162Z",
  },
  {
    d: "M4.47222 14.5912L2.8018 17.0237L11.7084 23.1399L13.3788 20.7073L4.47222 14.5912ZM3.63897 22.7003L6.12897 24.4102L12.5345 15.0822L10.0445 13.3723L3.63897 22.7003Z",
  },
  {
    d: "M20.9255 6.38773L21.803 8.35654L29.0117 5.14358L28.1341 3.17477L20.9255 6.38773ZM25.6581 10.0017L27.6734 9.10342L24.3084 1.55374L22.2931 2.45199L25.6581 10.0017Z",
  },
];

function Effects({
  fill = "#003739",
  flip = false,
  classnames,
}) {
  return (
    <motion.svg
      width="32"
      height="27"
      viewBox="0 0 31 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames("", classnames, {
        "effect-left": flip,
        "effect-right": !flip,
      })}
    >
      {paths.map(({ d }, index) => {
        const duration = 1.1 + index * 0.2;
        return (
          <motion.path
            initial={{
              y: 15,
              scale: 0,
              opacity: 1,
            }}
            animate={{
              y: 0,
              scale: 1.2,
              opacity: [0, 1, 1, 1, 0],
            }}
            transition={{
              duration,
              repeat: Infinity,
              delay: index * 0.2,
              // opacity: {
              //   delay: duration - 0.2,
              // },
            }}
            key={index}
            d={d}
            fill={fill}
          />
        );
      })}
    </motion.svg>
  );
}

export default Effects;
