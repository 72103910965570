import { Component } from "react";
import Effects from "../Effects";

const title = <h3>Нашият <span style={{position: "relative"}} className="color--gradient-y">екип <Effects fill="#029561" /> </span></h3>;
const desc = "Ние сме хората, които се грижим за развитието, поддръжката и приятната атмосфера в сървъра.";

let teamList = [
    {
        imgUrl: 'assets/images/team/01.jpg',
        imgAlt: 'Team Thumb',
        name: 'TOSHKO',
        dige: 'Lead Developer',
    },
    {
        imgUrl: 'assets/images/team/02.jpg',
        imgAlt: 'Team Thumb',
        name: 'Axel',
        dige: 'Lead Developer',
    },
    {
        imgUrl: 'assets/images/team/06.jpg',
        imgAlt: 'Team Thumb',
        name: 'Slubber',
        dige: 'Marketing',
    },
    {
        imgUrl: 'assets/images/team/03.jpg',
        imgAlt: 'Team Thumb',
        name: 'Syntom',
        dige: 'Management',
    },
    {
        imgUrl: 'assets/images/team/04.jpg',
        imgAlt: 'Team Thumb',
        name: 'Andrew Spasov',
        dige: 'Management',
    },
    {
        imgUrl: 'assets/images/team/05.jpg',
        imgAlt: 'Team Thumb',
        name: 'Doktora',
        dige: 'Developer',
    },
    {
        imgUrl: 'assets/images/team/07.jpg',
        imgAlt: 'Team Thumb',
        name: 'Cienight',
        dige: 'Developer',
    },
]

class Team extends Component {
    render() { 
        return (
            <section id="team" className="team padding-top padding-bottom bg--fixed" style={{backgroundImage: "linear-gradient(rgba(0, 149, 154, 0.7), rgba(7, 59, 76, 0.7)), url(/assets/images/team/mazenmap2.jpg)"}}>
                <div className="container">
                    <div className="section-header aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                        <div className="section-header__content">
                            {title}
                            <p>{desc}</p>
                        </div>
                    </div>
                    <div className="team__wrapper">
                        <div className="row justify-content-center row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5 g-3">
                            {teamList.map((val, i) => (
                                <div className="col" key={i}>
                                    <div className="team__item" data-aos="flip-right" data-aos-duration="1000">
                                        <div className="team__inner">
                                            <div className="team__thumb">
                                                <img style={{
                                                    borderRadius: '50%',
                                                    height: '150px',
                                                    minWidth: '150px',
                                                    maxWidth: '150px',
                                                    objectFit: 'cover'
                                                }}
                                                src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                            </div>
                                            <div className="team__content">
                                                <h5><a href="#">{val.name}</a></h5>
                                                <p>{val.dige}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Team;