import { Component } from "react";

const title = <h2 className="text-uppercase"> <span className="color--gradient-y"></span>Mazen Roleplay</h2>
// const titleImage = <img src="/assets/images/logo/mazen-logo.png" alt="Title Image" style={{maxWidth: "30%"}} />;
const desc = "Истории, които заслужават да бъдат изживяни!";
const btnText = "Присъедини се";


let socialList = [
    {
        imgUrl: 'assets/images/social/discord.svg',
        imgAlt: 'Social Thumb',
        siteLink: 'https://discord.gg/mazenrp',
    },
    // {
    //     imgUrl: 'assets/images/social/twitter.png',
    //     imgAlt: 'Social Thumb',
    //     siteLink: '#',
    // },
    {
        imgUrl: 'assets/images/social/instagram.svg',
        imgAlt: 'Social Thumb',
        siteLink: 'https://instagram.com/mazenrp',
    },
    // {
    //     imgUrl: 'assets/images/social/twitch.png',
    //     imgAlt: 'Social Thumb',
    //     siteLink: '#',
    // },
    {
        imgUrl: 'assets/images/social/tiktok.svg',
        imgAlt: 'TikTok',
        siteLink: 'https://tiktok.com/@mazenrp',
    },
    {
        imgUrl: 'assets/images/social/youtube.svg',
        imgAlt: 'Social Thumb',
        siteLink: 'https://youtube.com/@mazenrp',
    },
]

let bannerAppsImageList = [
    {
        imgUrl: 'assets/images/banner/apes/franklin.png',
        imgAlt: 'Banner Apps',
        duration: '1500',
    },
    {
        imgUrl: 'assets/images/banner/apes/michael.png',
        imgAlt: 'Banner Apps',
        duration: '1000',
    },
    {
        imgUrl: 'assets/images/banner/apes/trevor.png',
        imgAlt: 'Banner Apps',
        duration: '1500',
    },
    {
        imgUrl: 'assets/images/banner/apes/lester.png',
        imgAlt: 'Banner Apps',
        duration: '1000',
    },
]

class Banner extends Component {
    render() { 
        return (
            <section id="home" className="banner bg--fixed" style={{backgroundImage: "url(/assets/images/banner/asd.jpg)"}}>
                <div className="container">
                    <div className="banner__wrapper">
                        <div className="row g-5 align-items-center justify-content-center">
                            <div className="col">
                                <div className="banner__content text-center" data-aos="fade-up" data-aos-duration="2000">
                                    {/* {titleImage} */}
                                    {/* <section class=" z-2 relative mb-[100px] flex max-w-[570px] flex-col gap-2 text-center   lg:mt-[66px]   mt-[36px]">
                                        <h1 class=" text-center text-[#FCFCFC] drop-shadow-large leading-[60px] lg:p-0 lg:text-[48px] p-4 text-4xl">
                                            Търсенето на хостинг приключва тук.
                                        </h1>
                                        <h3 class="px-16 text-center text-xl text-[#C1C1C1]">
                                            Поддръжка, мощни сървъри, лесно управление. Имаме всичко!
                                        </h3>
                                    </section> */}
                                    {title}
                                    <p>{desc}</p>
                                    <ul className="social justify-content-center mb-5">
                                        {socialList.map((val, i) => (
                                            <li className="social__item" key={i}>
                                                <a href={val.siteLink} target="_blank" className="social__link"><img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></a>
                                            </li>
                                        ))}
                                    </ul>
                                    <a href={socialList[0].siteLink} target="_blank" className="default-btn">{btnText}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dolu-fiu-fiu">
                    <div className="lqvo-fiu"> </div>
                    <div className="dqsno-fiu"></div>
                </div>
                {/* <ul className="banner__apes">
                    {bannerAppsImageList.map((val, i) => (
                        <li className="banner__apes-item" key={i}>
                            <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                        </li>
                    ))}
                </ul> */}
            </section>
        );
    }
}
 
export default Banner;