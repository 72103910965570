import { Component } from "react";
import { CountUp } from 'use-count-up'


const btnText = "Вземи whitelist";


let mintList = [
    {
        title: 'Discord',
        count: 10000,
        desc: 'Members',
    },
    {
        title: 'Players',
        count: 128,
        desc: 'Ingame',
    },
    {
        title: 'Players',
        count: 1000,
        desc: 'Whitelisted',
    },
]

class Mint extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isCounting: false
        };
    }

    componentDidMount() {
        // Attach the AOS event listener
        document.addEventListener('aos:in', (event) => {
            if (event.detail.getAttribute('data-aos-id') === 'mint-animation') {
                this.setState({ isCounting: true });
            }
        });
    }
    
    render() { 
        return (
            <section id="mint" className="mint padding-top padding-bottom bg--fixed" style={{backgroundImage: "linear-gradient(rgba(0, 149, 154, 0.7), rgba(7, 59, 76, 0.7)), url(/assets/images/mint/mazenmap1.jpg)"}}>
                <div className="container">
                    <div className="mint__wrapper">
                        <div className="row g-5 align-items-end">
                            <div className="col-lg-6">
                                <div className="mint__content aos-animate aos-init" data-aos="fade-right" data-aos-duration="1000" data-aos-id="mint-animation">
                                    <div className="row gy-5 gx-4 align-items-center">
                                        {mintList.map((val, i) => (
                                            <div className="col-6" key={i}>
                                                <div className="mint__item">
                                                    <div className="mint__inner">
                                                        <h6 className="mint__sub-title">{val.title}</h6>
                                                        <h2 className="mint__numbers"><CountUp isCounting={this.state.isCounting} end={val.count} duration={3.5} /></h2>
                                                        <h4 className="mint__name text-uppercase">{val.desc}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <div className="col-6">
                                            <div className="text-start">
                                                <a href="#" className="default-btn">{btnText}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mint__thumb aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                                    <img src="assets/images/mint/01.png" alt="Minting Image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="monkey-icon">
                    <img src="assets/images/mint/monkey.png" alt="Monkey Icon" />
                </div>
            </section>
        );
    }
}
 
export default Mint;